import React, { useState } from 'react';
import { X } from 'lucide-react';
import './SelectRegion.css';

const availableCountries = {
    'ar': "Argentina", 'au': "Australia", 'at': "Austria", 'be': "Belgium",
    'br': "Brazil", 'bg': "Bulgaria", 'ca': "Canada", 'cn': "China",
    'co': "Colombia", 'cz': "Czech Republic", 'eg': "Egypt", 'fr': "France",
    'de': "Germany", 'gr': "Greece", 'hk': "Hong Kong", 'hu': "Hungary",
    'in': "India", 'id': "Indonesia", 'ir': "Ireland", 'il': "Israel",
    'it': "Italy", 'jp': "Japan", 'lv': "Latvia", 'lt': "Lithuania",
    'my': "Malaysia", 'mx': "Mexico", 'ma': "Morocco", 'nl': "Netherlands",
    'nz': "New Zealand", 'ng': "Nigeria", 'no': "Norway", 'ph': "Philippines",
    'pl': "Poland", 'pt': "Portugal", 'ro': "Romania", 'sa': "Saudi Arabia",
    'rs': "Serbia", 'sg': "Singapore", 'sk': "Slovakia", 'si': "Slovenia",
    'za': "South Africa", 'kr': "South Korea", 'se': "Sweden", 'ch': "Switzerland",
    'tw': "Taiwan", 'th': "Thailand", 'tr': "Turkey", 'ae': "UAE",
    'ua': "Ukraine", 'gb': "United Kingdom", 'us': "United States", 've': "Venezuela"
};

const SelectRegion = ({ isOpen, onClose, onSelectCountries, selectedCountries }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [tempSelectedCountries, setTempSelectedCountries] = useState(selectedCountries || []);

    const filteredCountries = Object.entries(availableCountries)
        .filter(([code, name]) => 
            name.toLowerCase().includes(searchTerm.toLowerCase()) || 
            code.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => a[1].localeCompare(b[1]));

        const handleCountryToggle = (countryCode) => {
            setTempSelectedCountries(prev => {
                const updatedCountries = prev.includes(countryCode)
                    ? prev.filter(code => code !== countryCode)
                    : [...prev, countryCode];
                
                return updatedCountries;
            });
        };

    const handleSave = () => {
        onSelectCountries(tempSelectedCountries);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="select-region-modal-overlay">
            <div className="select-region-modal-container">
                {/* Modal Header */}
                <div className="select-region-modal-header">
                    <h2>Select Regions</h2>
                    <button 
                        onClick={onClose} 
                        className="select-region-modal-close-btn"
                    >
                        <X className="h-6 w-6" />
                    </button>
                </div>

                {/* Search Input */}
                <div className="select-region-modal-search">
                    <input 
                        type="text" 
                        placeholder="Search countries..." 
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>

                {/* Countries List */}
                <div className="select-region-modal-list">
                    <ul>
                        {filteredCountries.map(([code, name]) => (
                            <li 
                                key={code}
                                className={tempSelectedCountries.includes(code) ? 'selected' : ''}
                                onClick={() => handleCountryToggle(code)}
                            >
                                <input 
                                    type="checkbox" 
                                    className="country-checkbox"
                                    checked={tempSelectedCountries.includes(code)}
                                    onChange={() => handleCountryToggle(code)}
                                    onClick={() => handleCountryToggle(code)}
                                />
                                <span className="country-name">{name}</span>
                                <span className="country-code">{code.toUpperCase()}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* No Results Message */}
                {filteredCountries.length === 0 && (
                    <div className="select-region-modal-no-results">
                        No countries found
                    </div>
                )}

                {/* Save Button */}
                <div className="select-region-modal-save-btn">
                    <button onClick={handleSave}>
                        Save Regions
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SelectRegion;